import { useTranslation } from "react-i18next";
import Button from "src/components/Actions/Button";
import useForm from "src/hooks/useForm";
import useEntityTranslation from "src/hooks/useEntityTranslation";
import FormCheckbox from "src/components/Form/FormCheckbox";
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { PersonalData, RiderTrainingsClient, Training, UserCoupon } from 'src/api/stable/Booking';
import { TrainingTypeImage } from 'src/components/Stable/TrainingTypeImage';
import { Horse, Instructor, TrainingType, TrainingTypeTranslation } from 'src/api/stable/Stable';
import ContentParse from 'src/components/Cms/ContentParse';
import LoadingOverlay from 'src/components/Feedback/LoadingOverlay';
import FormAlert from 'src/components/Form/FormAlert';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import { PaymentMethod, PaymentMethodTranslation } from 'src/api/financial/Payments';
import BookTrainingHeader from './BookTrainingHeader';
import useUser from "src/hooks/useUser";
import PageLink from "src/components/Cms/PageLink";
import useFunctionality from "src/hooks/stores/useFunctionality";
import moment from "moment";

interface Agreement {
  id: string;
  title: string;
  link?: string;
  visible?: boolean;
  required?: boolean;
}

interface ConfirmationComponentProps {
  onConfirm: (training: Training, agreements: string[]) => void;
  type: TrainingType,
  horse?: Horse,
  instructor?: Instructor,
  startDate: Date,
  endDate: Date,
  paymentMethod?: PaymentMethod,
  coupon?: UserCoupon,
  personalData: PersonalData
  requireEquestrianCentreAgreements?: boolean;
  onGoBack: () => void;
}

interface ConfirmationForm {
  agreements: string[];
}

const Confirmation = (props: ConfirmationComponentProps) => {
  const { type, horse, instructor, startDate, endDate, paymentMethod, coupon, personalData, requireEquestrianCentreAgreements, onConfirm, onGoBack } = props;
  const { t, i18n } = useTranslation();
  const paymentsFunctionality = useFunctionality('module-payments');
  const navigate = useLocalizedNavigate();
  const user = useUser();

  const agreements: Agreement[] = [
    { id: 'service', title: t('auth.signUp.agreements'), link: '3b9f58a4-2682-4fe3-b395-6313e8d1cf56', visible: user === undefined, required: true },
    { id: 'stable', title: t('auth.signUp.agreements'), link: '135bb7ab-e24b-4cb3-92ab-0f7b1d057717', required: requireEquestrianCentreAgreements, visible: requireEquestrianCentreAgreements }
  ]

  const apiConfiguration = useApiConfiguration();
  const apiClient = new RiderTrainingsClient(apiConfiguration);

  const typeTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();
  const paymentTranslation = useEntityTranslation<PaymentMethod, PaymentMethodTranslation>();
  const form = useForm<ConfirmationForm>();

  const onSubmit = () => {
    const dto = {
      source: 0,
      typeId: type.id,
      start: startDate,
      end: moment(endDate).startOf('minute').toDate(),
      personalData: {
        givenName: personalData.givenName,
        surname: personalData.surname,
        email: personalData.email,
        phoneNumber: personalData.phoneNumber
      }
    } as Training;
    if (horse) dto.horseId = horse.id!;
    if (instructor) dto.instructorId = instructor.id!;
    if (paymentMethod) dto.paymentMethodId = paymentMethod.id!;
    if (coupon) dto.couponId = coupon.id!;
    apiClient
      .create(dto)
      .then((response) => onConfirm(response, form.data.agreements ?? []))
      .catch(form.catchAnyException)
      .finally(() => {
        form.setPending(false);
      });
  }

  const redirectToLogin = () => navigate('/auth/sign-in/');

  return (
    <form onSubmit={(e) => form.onSubmit(e, onSubmit)}>
      <div className="relative max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
        {form.pending && <LoadingOverlay />}
        <FormAlert
          code={form.error}
          errorMessages={{
            ...t("common.errors", { returnObjects: true }),
            ...t("stable.trainings.booking.errors", { returnObjects: true })
          }}
        />
        {form.error === 403 && <div className="flex w-full mb-5 justify-end"><Button type="button" colorName="primary" className="px-5 py-1 justify-end" onClick={redirectToLogin}>{t('auth.signIn.action')}</Button></div>}
        <BookTrainingHeader onClickBack={onGoBack} submitButton disabledBack={form.pending} disabledNext={form.pending} />
        <div className="lg:flex lg:justify-between">
          <div className="lg:w-5/12 lg:pr-3">
            <h2 className="text-3xl pb-5 mb-5 border-b border-gray-200">{t('stable.trainings.booking.yourTraining')}</h2>
            <div className="lg:flex pb-5 border-b border-gray-100 mb-5">
              <div className="hidden xl:block lg:pr-5 lg:w-48 h-100">
                <TrainingTypeImage id={type.id!} className="max-w-100 max-h-100 mx-auto" />
              </div>
              <div className="lg:flex-1">
                <h3 className="text-2xl pb-5">{typeTranslation.getCurrentTranslation(type)?.name}</h3>
                <div className="text-sm text-gray-500"><ContentParse>{typeTranslation.getCurrentTranslation(type)?.description ?? ""}</ContentParse></div>
                <div>{t('stable.trainingTypes.fields.price')}: {Intl.NumberFormat(i18n.resolvedLanguage, { style: "currency", currency: "PLN" }).format(type.price)}</div>
              </div>
            </div>
            <div className="mb-5 pb-5 border-b border-gray-100">
              <h4 className="text-lg text-gray-500 mb-3">{t('stable.trainings.booking.date')}:</h4>
              <h3 className="text-2xl">{startDate.toLocaleString(i18n.resolvedLanguage, { timeStyle: 'short', dateStyle: 'full' })}</h3>
            </div>
            {paymentsFunctionality && <div className="mb-5 pb-5 border-b border-gray-100">
              <h4 className="text-lg text-gray-500 mb-3">{t('accountancy.payments.method.single')}</h4>
              <h3 className="text-2xl mb-3">{paymentTranslation.getCurrentTranslation(paymentMethod)?.title}</h3>
              <div className="text-sm text-gray-500"><ContentParse>{paymentTranslation.getCurrentTranslation(paymentMethod)?.description ?? ""}</ContentParse></div>
            </div>}
          </div>
          <div className="lg:w-7/12 pl-5">
            <h2 className="text-3xl pb-5 mb-5 border-b border-gray-200">{t('auth.yourPersonalData')}</h2>
            <div className="mb-5 pb-5 border-b border-gray-100 lg:flex">
              <h4 className="lg:w-2/3 text-gray-500 mb-3">{t('users.fields.givenName')}</h4>
              <h3 className="lg:w-3/4 mb-3">{personalData.givenName}</h3>
            </div>
            <div className="mb-5 pb-5 border-b border-gray-100 lg:flex">
              <h4 className="lg:w-2/3 text-gray-500 mb-3">{t('users.fields.surname')}</h4>
              <h3 className="lg:w-3/4 mb-3">{personalData.surname}</h3>
            </div>
            <div className="mb-5 pb-5 border-b border-gray-100 lg:flex">
              <h4 className="lg:w-2/3 text-gray-500 mb-3">{t('users.fields.email')}</h4>
              <h3 className="lg:w-3/4 mb-3">{personalData.email}</h3>
            </div>
            <div className="mb-5 pb-5 border-b border-gray-100 lg:flex">
              <h4 className="lg:w-2/3 text-gray-500 mb-3">{t('auth.fields.phoneNumber')}</h4>
              <h3 className="lg:w-3/4 mb-3">{personalData.phoneNumber || <span className="text-gray-300">{t('common.notProvided')}</span>}</h3>
            </div>
            {agreements.filter(a => a.visible !== false).length > 0 &&
              <>
                <h2 className="text-3xl pb-5 mb-5 border-b border-gray-200">{t('footer.termsAndAgreements')}</h2>
                <div className="mb-5 pb-5 border-b border-gray-100">
                  <FormCheckbox.Set title="Terms and Agreements">
                    {agreements
                      .filter(a => a.visible !== false)
                      .map((a, index) => <div key={index} className="flex">

                        <FormCheckbox.Input key={a.id} {...form.input('agreements[]', 'checkbox', { value: a.id, required: a.required })} />
                        <div className="ml-3">
                          {a.title} {a.link && <PageLink id={a.link} className="text-[--color-primary-700] hover:text-[--color-primary-600] active:text-[--color-primary-500]" />}
                        </div>
                      </div>)}
                  </FormCheckbox.Set>
                </div>
              </>
            }
          </div>
        </div>
        <BookTrainingHeader onClickBack={onGoBack} submitButton disabledBack={form.pending} disabledNext={form.pending} />
      </div >
    </form >
  )
}

export default Confirmation;