
import { useEffect, useState } from 'react';
import useEntityTranslation from 'src/hooks/useEntityTranslation';
import GridSelect, { GridSelectOption } from 'src/components/Form/GridSelect';
import { useTranslation } from 'react-i18next';
import useUser from 'src/hooks/useUser';
import useApiConfiguration from 'src/hooks/useApiConfiguration';
import { TrainingTypeImage } from 'src/components/Stable/TrainingTypeImage';
import ContentParse from 'src/components/Cms/ContentParse';
import Spinner from 'src/components/Feedback/Spinner';
import Alert from 'src/components/Feedback/Alert';
import LoadingOverlay from 'src/components/Feedback/LoadingOverlay';
import { RiderTrainingsClient, TrainingType, TrainingTypeTranslation } from 'src/api/stable/Booking';
import BookTrainingHeader from './BookTrainingHeader';

export interface TrainingTypesComponentProps {
  type?: TrainingType;
  onSelect: (value?: TrainingType) => void;
}

const TrainingTypes = (props: TrainingTypesComponentProps) => {
  const { type, onSelect } = props;
  const { t, i18n } = useTranslation();

  const apiConfiguration = useApiConfiguration();
  const riderTrainingClient = new RiderTrainingsClient(apiConfiguration);
  const [trainingTypes, setTrainingTypes] = useState<TrainingType[]>([]);
  const [loading, setLoading] = useState(false);

  const user = useUser();
  const [trainingType, setTrainingType] = useState<string | undefined>(type?.id);
  const entityTranslation = useEntityTranslation<TrainingType, TrainingTypeTranslation>();

  useEffect(() => {
    onSelect();
    setLoading(true);
    riderTrainingClient
      .trainingTypes()
      .then(response => setTrainingTypes(response.items ?? []))
      .finally(() => setLoading(false));
  }, []);

  const options = trainingTypes.map((type) => ({
    id: type.id,
    value: type.id,
    image: <TrainingTypeImage id={type.id!} className="max-w-100 max-h-100 mx-auto rounded-md aspect-[4/5]" />,
    label: entityTranslation.getCurrentTranslation(type)?.name,
    description: <div><ContentParse>{entityTranslation.getCurrentTranslation(type)?.description ?? ""}</ContentParse><div className="flex justify-between border-t border-gray-100 mt-4 pt-4"><div>{t('stable.trainingTypes.fields.duration')}: {Intl.NumberFormat(i18n.resolvedLanguage, { style: "unit", unit: "minute" }).format(Math.ceil(type.duration))}</div><div>{t('stable.trainingTypes.fields.price')}: {Intl.NumberFormat(i18n.resolvedLanguage, { style: "currency", currency: "PLN" }).format(type.price)}</div></div></div>,
    disabled: !type.isAvailableForGuest && user === undefined
  }) as GridSelectOption);

  const onSubmit = () => {
    const type = trainingTypes.find(t => t.id == trainingType);
    if (type) {
      onSelect(type);
    }
  }

  return (
    <div className="relative max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto px-4 md:px-0 mb-8">
      {loading && <LoadingOverlay />}
      <BookTrainingHeader onClickNext={onSubmit} disabledNext={!trainingType} >
        {t('stable.trainings.booking.selectTrainingType')}
      </BookTrainingHeader>
      <div className="border-y border-y-gray-200 py-5 my-5">
        {typeof trainingTypes === 'undefined' && <Spinner className="mx-auto h-8" />}
        <GridSelect options={options} value={trainingType} onChange={(e) => setTrainingType(e.target.value)} />
        <Alert.Information title={t('stable.trainingTypes.availability.title')} noClose >{t('stable.trainingTypes.availability.description')}</Alert.Information>
      </div>
      <BookTrainingHeader onClickNext={onSubmit} disabledNext={!trainingType} />
    </div >
  )
}

export default TrainingTypes;