import _ from 'lodash';
import Calendar from './Calendar';
import { useEffect, useRef, useState } from 'react';
import Confirmation from './Confirmation';
import { BookTrainingStep } from './BookTrainingStep';
import Complete from './Complete';
import Payment from './Payment';
import Breadcrumbs from 'src/components/Navigation/Breadcrumbs';
import useApplicationDispatch from 'src/hooks/useApplicationDispatch';
import { setBreadcrumbs } from 'src/store/application/actions';
import { useTranslation } from 'react-i18next';
import { TrainingType, Horse, Instructor } from 'src/api/stable/Stable';
import TrainingTypes from './TrainingType';
import Instructors from './Instructors';
import Horses from './Horses';
import useCommonEventTracking from 'src/hooks/useCommonEventTracking';
import { PersonalData, Training, UserCoupon } from 'src/api/stable/Booking';
import useConfigurationState from 'src/hooks/useConfigurationState';
import useAuthenticated from 'src/hooks/useAuthenticated';
import { AuthorizationScope } from 'src/store/configuration/state';
import useLocalizedNavigate from 'src/hooks/useNavigate';
import { PaymentMethod } from 'src/api/financial/Payments';
import PersonalDataView from './PersonalData';
import useFunctionality from 'src/hooks/stores/useFunctionality';
import { MetaHead } from 'src/components/Meta/MetaHead';


const BookTraining = () => {
  const { t } = useTranslation();
  const configurationState = useConfigurationState();
  const paymentsFunctionality = useFunctionality('module-payments');
  const isAuthenticated = useAuthenticated();
  const navigate = useLocalizedNavigate();
  const unauthenticatedInterval = useRef<NodeJS.Timeout>();
  const [trainingType, setTrainingType] = useState<TrainingType | undefined>();
  const [instructor, setInstructor] = useState<Instructor | undefined>();
  const [horse, setHorse] = useState<Horse | undefined>();
  const [startDate, setStartDate] = useState<Date | undefined>();
  const [endDate, setEndDate] = useState<Date | undefined>();
  const [personalData, setPersonalData] = useState<PersonalData | undefined>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | undefined>();
  const [coupon, setCoupon] = useState<UserCoupon | undefined>();
  const [training, setTraining] = useState<Training | undefined>();
  const [_agreements, setAgreements] = useState<string[]>([]);
  const [step, setStep] = useState(BookTrainingStep.TrainingType);

  const eventTracking = useCommonEventTracking();

  const [journeySteps, setJourneySteps] = useState<BookTrainingStep[]>([]);

  const meetsAuthorizationScope = (scope: AuthorizationScope): boolean => {
    switch (String(scope)) {
      case String(AuthorizationScope.Any):
        return true;
      case String(AuthorizationScope.Users):
        return isAuthenticated;
      case String(AuthorizationScope.Noone):
        return false;
      default:
        return false;
    }
  }

  const navigateToSignIn = () => navigate('/auth/sign-in/');

  useEffect(() => {
    const steps = [];
    const isGroupTraining = (trainingType?.maximalRiders !== undefined && trainingType?.maximalRiders > 1);
    const isPlannedSessionsTraining = trainingType?.isLimitedToDefinedTerms ?? false;
    if (meetsAuthorizationScope(configurationState.booking?.selectTrainingType)) {
      steps.push(BookTrainingStep.TrainingType);
    }
    if (meetsAuthorizationScope(configurationState.booking?.selectInstructor) && !isGroupTraining && !isPlannedSessionsTraining) {
      steps.push(BookTrainingStep.Instructor);
    }
    if (meetsAuthorizationScope(configurationState.booking?.selectHorse) && !isGroupTraining && !isPlannedSessionsTraining) {
      steps.push(BookTrainingStep.Horse);
    }
    steps.push(BookTrainingStep.Date);
    steps.push(BookTrainingStep.PersonalData);
    steps.push(BookTrainingStep.Confirmation);
    steps.push(BookTrainingStep.Payment);
    steps.push(BookTrainingStep.Complete);
    setJourneySteps(steps);
    if (configurationState.booking.guests === false && isAuthenticated === false) {
      unauthenticatedInterval.current = setTimeout(() => {
        if (!isAuthenticated) {
          navigateToSignIn();
        }
      }, 1000);
    }
  }, [configurationState.booking, trainingType, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && unauthenticatedInterval.current) {
      clearInterval(unauthenticatedInterval.current);
    }
  }, [isAuthenticated]);

  const getPreviousStep = () => {
    const currentIndex = journeySteps.indexOf(step);
    if (currentIndex === 0) {
      return journeySteps[0];
    }
    return journeySteps[currentIndex - 1];
  }
  const getNextStep = () => {
    const currentIndex = journeySteps.indexOf(step);
    if (currentIndex === journeySteps.length - 1) {
      return journeySteps[journeySteps.length - 1];
    }
    return journeySteps[currentIndex + 1];
  }

  useEffect(() => {
    switch (step) {
      case BookTrainingStep.TrainingType:
        eventTracking.trackEvent('booking', 'step', 'training-type');
        break;
      case BookTrainingStep.Instructor:
        eventTracking.trackEvent('booking', 'step', 'instructor');
        break;
      case BookTrainingStep.Horse:
        eventTracking.trackEvent('booking', 'step', 'horse');
        break;
      case BookTrainingStep.Date:
        eventTracking.trackEvent('booking', 'step', 'date');
        break;
      case BookTrainingStep.PersonalData:
        eventTracking.trackEvent('booking', 'step', 'personal-data');
        break;
      case BookTrainingStep.Confirmation:
        eventTracking.trackEvent('booking', 'step', 'confirmation');
        break;
      case BookTrainingStep.Payment:
        eventTracking.trackEvent('booking', 'step', 'payment');
        break;
      case BookTrainingStep.Complete:
        eventTracking.trackEvent('booking', 'step', 'complete');
        break;
      default:
        break;
    }
  }, [step]);

  const previousStep = () => setStep(getPreviousStep());
  const nextStep = () => setStep(getNextStep());

  const onGoBack = () => {
    previousStep();
  }

  const applicationDispatch = useApplicationDispatch();
  useEffect(() => {
    const breadcrumbs = [{ label: 'stable.trainings.booking.header', href: `/stable/book-training` }];
    applicationDispatch(setBreadcrumbs(breadcrumbs));
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  useEffect(() => {
    if (!trainingType) return;
    nextStep();
  }, [journeySteps]);

  const isFullScreen = step === BookTrainingStep.Date || step === BookTrainingStep.Complete;
  const onSelectTrainingTypes = (t?: TrainingType) => {
    setTrainingType(t);
  }
  const onSelectInstructor = (i?: Instructor) => {
    setInstructor(i);
    nextStep();
  }

  const onSelectHorse = (h?: Horse) => {
    setHorse(h);
    nextStep();
  }

  const onSelectDate = (s: Date, e: Date) => {
    setStartDate(s);
    setEndDate(e);
    if (s && e) {
      nextStep();
    }
  }

  const onSetPersonalData = (pd: PersonalData, pm?: PaymentMethod, c?: UserCoupon) => {
    setPersonalData(pd);
    setPaymentMethod(pm);
    setCoupon(c);
    if (pd && (!paymentsFunctionality || pm || c)) {
      nextStep();
    }
  }

  const onConfirm = (t: Training, a: string[]) => {
    setTraining(t);
    setAgreements(a);
    if (t) {
      setStep(paymentMethod?.isPrepaid ? BookTrainingStep.Payment : BookTrainingStep.Complete);
    }
  }

  return (
    <>
      <MetaHead title={t('stable.trainings.booking.header')} />
      <div>
        {isFullScreen && <div className="max-w-sm md:max-w-full px-4 md:px-8 mx-auto mb-8"><Breadcrumbs /></div>}
        {!isFullScreen && <div className="max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-7xl mx-auto mb-8"><Breadcrumbs /></div>}
        {step === BookTrainingStep.TrainingType && <TrainingTypes type={trainingType} onSelect={onSelectTrainingTypes} />}
        {step === BookTrainingStep.Instructor && <Instructors type={trainingType!} instructor={instructor} onSelect={onSelectInstructor} onGoBack={onGoBack} />}
        {step === BookTrainingStep.Horse && <Horses type={trainingType!} instructor={instructor} horse={horse} onSelect={onSelectHorse} onGoBack={onGoBack} />}
        {step === BookTrainingStep.Date && <Calendar instructor={instructor} horse={horse} type={trainingType!} onSelect={onSelectDate} onGoBack={onGoBack} />}
        {step === BookTrainingStep.PersonalData && <PersonalDataView startDate={startDate!} endDate={endDate!} instructor={instructor} horse={horse} type={trainingType!} personalData={personalData} onSelect={onSetPersonalData} onGoBack={onGoBack} requirePhoneNumber={configurationState.booking.requirePhoneNumber} />}
        {step === BookTrainingStep.Confirmation && <Confirmation onConfirm={onConfirm} type={trainingType!} instructor={instructor} horse={horse} startDate={startDate!} endDate={endDate!} personalData={personalData!} paymentMethod={paymentMethod} coupon={coupon} onGoBack={onGoBack} requireEquestrianCentreAgreements={configurationState.booking.requireEquestrianCentreAgreements} />}
        {step === BookTrainingStep.Payment && <Payment training={training!} paymentMethod={paymentMethod!} />}
        {step === BookTrainingStep.Complete && <Complete training={training!} />}
      </div>
    </>
  )
}

export default BookTraining;